@use 'styles/new-design/mixins/elevation' as elevation;

.mat-mdc-card:not([class*='mat-elevation-z']),
.ds-styles
  .mat-expansion-panel:not([class*='default'], [class*='mat-elevation-z']),
ds-app-wrapper
  .mat-expansion-panel:not([class*='default'], [class*='mat-elevation-z']) {
  @include elevation.elevation(1);
}
mat-tab-body .mat-mdc-card:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.mdc-fab:not([class*='mat-elevation-z']) {
  @include elevation.elevation(10);
}

.mdc-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  @include elevation.elevation(20);
}

.mat-mdc-snack-bar-container:not([class*='mat-elevation-z']) {
  @include elevation.elevation(10);
}

@mixin mat-elevation-classes {
  @for $i from 0 through 24 {
    .mat-elevation-z#{$i} {
      @include elevation.elevation($i);
    }
  }
}

@include mat-elevation-classes;
