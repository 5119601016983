$pd-access-denied: '\ea01';
$pd-alerts: '\ea02';
$pd-axis-y-rotate: '\ea03';
$pd-axis-z-rotate: '\ea04';
$pd-carrier: '\ea05';
$pd-carrier-management: '\ea06';
$pd-code-reader: '\ea07';
$pd-crane-functions: '\ea08';
$pd-crane-lifting-power: '\ea09';
$pd-crane-outreach: '\ea0a';
$pd-documents: '\ea0b';
$pd-engine: '\ea0c';
$pd-equipment-status: '\ea0d';
$pd-filter: '\ea0e';
$pd-fleet-monitor: '\ea0f';
$pd-fleet-status: '\ea10';
$pd-geofence: '\ea11';
$pd-height-limitation: '\ea12';
$pd-job-planning: '\ea13';
$pd-local_shipping_outlined: '\ea14';
$pd-location: '\ea15';
$pd-location-status: '\ea16';
$pd-lubricant-1: '\ea17';
$pd-lubricant-2: '\ea18';
$pd-lubricant-3: '\ea19';
$pd-lubricant-4: '\ea1a';
$pd-lubricant-5: '\ea1b';
$pd-mail-read-and-prio: '\ea1c';
$pd-maintenance-checklist: '\ea1d';
$pd-market-model: '\ea1e';
$pd-no-search-result: '\ea1f';
$pd-oh-crane: '\ea20';
$pd-oh-dailyPTO: '\ea21';
$pd-oh-emergencyModeActivation: '\ea22';
$pd-oh-flyjib: '\ea23';
$pd-oh-hookloader: '\ea24';
$pd-oh-leverNotNeutral: '\ea25';
$pd-oh-loadingOperations: '\ea26';
$pd-oh-manualOverride: '\ea27';
$pd-oh-mechv: '\ea28';
$pd-oh-platforms: '\ea29';
$pd-oh-taillift: '\ea2a';
$pd-oh-tippingOperations: '\ea2b';
$pd-oh-tool: '\ea2c';
$pd-oh-truckMountedForklifts: '\ea2d';
$pd-oh-winch: '\ea2e';
$pd-oh-winch_rtc: '\ea2f';
$pd-oh-workingBasket: '\ea30';
$pd-oh-workingBasket_leverNotNeutral: '\ea31';
$pd-pac-online: '\ea32';
$pd-palcode: '\ea33';
$pd-paldrive: '\ea34';
$pd-palshow-web: '\ea35';
$pd-palsoft-updates: '\ea36';
$pd-route: '\ea37';
$pd-sentiment-dissatisfied: '\ea38';
$pd-sentiment-satisfied: '\ea39';
$pd-service: '\ea3a';
$pd-service-cockpit: '\ea3b';
$pd-service-partner-search: '\ea3c';
$pd-smarteye: '\ea3d';
$pd-software-options: '\ea3e';
$pd-stability-chart: '\ea3f';
$pd-stabilizer-left: '\ea40';
$pd-stabilizer-left-disabled: '\ea41';
$pd-stabilizer-right: '\ea42';
$pd-stabilizer-right-disabled: '\ea43';
$pd-telematic: '\ea44';
$pd-telematic-doctor: '\ea45';
$pd-telematic-lab: '\ea46';
$pd-utilization: '\ea47';
$pd-weight: '\ea48';

@font-face {
  font-family: PalfingerIcons;
  src: url('/assets/fonts/PalfingerIcons.eot');
  src:
    url('/assets/fonts/PalfingerIcons.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/PalfingerIcons.woff2') format('woff2'),
    url('/assets/fonts/PalfingerIcons.woff') format('woff'),
    url('/assets/fonts/PalfingerIcons.ttf') format('truetype'),
    url('/assets/fonts/PalfingerIcons.svg#PalfingerIcons') format('svg');
  font-style: normal;
  font-weight: 400;
}

%pd {
  display: inline-block;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 PalfingerIcons;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.pd {
  @extend %pd;
}

.pd-lg {
  vertical-align: -15%;
  line-height: 0.75em;
  font-size: 1.33333333em;
}

.pd-2x {
  font-size: 2em;
}

.pd-3x {
  font-size: 3em;
}

.pd-4x {
  font-size: 4em;
}

.pd-5x {
  font-size: 5em;
}

.pd-fw {
  width: 1.28571429em;
  text-align: center;
}

.pd-access-denied::before {
  content: '\ea01';
}

.pd-alerts::before {
  content: '\ea02';
}

.pd-axis-y-rotate::before {
  content: '\ea03';
}

.pd-axis-z-rotate::before {
  content: '\ea04';
}

.pd-carrier::before {
  content: '\ea05';
}

.pd-carrier-management::before {
  content: '\ea06';
}

.pd-code-reader::before {
  content: '\ea07';
}

.pd-crane-functions::before {
  content: '\ea08';
}

.pd-crane-lifting-power::before {
  content: '\ea09';
}

.pd-crane-outreach::before {
  content: '\ea0a';
}

.pd-documents::before {
  content: '\ea0b';
}

.pd-engine::before {
  content: '\ea0c';
}

.pd-equipment-status::before {
  content: '\ea0d';
}

.pd-filter::before {
  content: '\ea0e';
}

.pd-fleet-monitor::before {
  content: '\ea0f';
}

.pd-fleet-status::before {
  content: '\ea10';
}

.pd-geofence::before {
  content: '\ea11';
}

.pd-height-limitation::before {
  content: '\ea12';
}

.pd-job-planning::before {
  content: '\ea13';
}

.pd-local_shipping_outlined::before {
  content: '\ea14';
}

.pd-location::before {
  content: '\ea15';
}

.pd-location-status::before {
  content: '\ea16';
}

.pd-lubricant-1::before {
  content: '\ea17';
}

.pd-lubricant-2::before {
  content: '\ea18';
}

.pd-lubricant-3::before {
  content: '\ea19';
}

.pd-lubricant-4::before {
  content: '\ea1a';
}

.pd-lubricant-5::before {
  content: '\ea1b';
}

.pd-mail-read-and-prio::before {
  content: '\ea1c';
}

.pd-maintenance-checklist::before {
  content: '\ea1d';
}

.pd-market-model::before {
  content: '\ea1e';
}

.pd-no-search-result::before {
  content: '\ea1f';
}

.pd-oh-crane::before {
  content: '\ea20';
}

.pd-oh-dailyPTO::before {
  content: '\ea21';
}

.pd-oh-emergencyModeActivation::before {
  content: '\ea22';
}

.pd-oh-flyjib::before {
  content: '\ea23';
}

.pd-oh-hookloader::before {
  content: '\ea24';
}

.pd-oh-leverNotNeutral::before {
  content: '\ea25';
}

.pd-oh-loadingOperations::before {
  content: '\ea26';
}

.pd-oh-manualOverride::before {
  content: '\ea27';
}

.pd-oh-mechv::before {
  content: '\ea28';
}

.pd-oh-platforms::before {
  content: '\ea29';
}

.pd-oh-taillift::before {
  content: '\ea2a';
}

.pd-oh-tippingOperations::before {
  content: '\ea2b';
}

.pd-oh-tool::before {
  content: '\ea2c';
}

.pd-oh-truckMountedForklifts::before {
  content: '\ea2d';
}

.pd-oh-winch::before {
  content: '\ea2e';
}

.pd-oh-winch_rtc::before {
  content: '\ea2f';
}

.pd-oh-workingBasket::before {
  content: '\ea30';
}

.pd-oh-workingBasket_leverNotNeutral::before {
  content: '\ea31';
}

.pd-pac-online::before {
  content: '\ea32';
}

.pd-palcode::before {
  content: '\ea33';
}

.pd-paldrive::before {
  content: '\ea34';
}

.pd-palshow-web::before {
  content: '\ea35';
}

.pd-palsoft-updates::before {
  content: '\ea36';
}

.pd-route::before {
  content: '\ea37';
}

.pd-sentiment-dissatisfied::before {
  content: '\ea38';
}

.pd-sentiment-satisfied::before {
  content: '\ea39';
}

.pd-service::before {
  content: '\ea3a';
}

.pd-service-cockpit::before {
  content: '\ea3b';
}

.pd-service-partner-search::before {
  content: '\ea3c';
}

.pd-smarteye::before {
  content: '\ea3d';
}

.pd-software-options::before {
  content: '\ea3e';
}

.pd-stability-chart::before {
  content: '\ea3f';
}

.pd-stabilizer-left::before {
  content: '\ea40';
}

.pd-stabilizer-left-disabled::before {
  content: '\ea41';
}

.pd-stabilizer-right::before {
  content: '\ea42';
}

.pd-stabilizer-right-disabled::before {
  content: '\ea43';
}

.pd-telematic::before {
  content: '\ea44';
}

.pd-telematic-doctor::before {
  content: '\ea45';
}

.pd-telematic-lab::before {
  content: '\ea46';
}

.pd-utilization::before {
  content: '\ea47';
}

.pd-weight::before {
  content: '\ea48';
}
