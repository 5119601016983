@use './elevation' as elevation;

@mixin micro-fab($size: 24px) {
  @include elevation.elevation(3, center, calc(0.5 * var(--ds-spacing)));
  width: $size !important;
  height: $size !important;
  line-height: $size !important;
  padding: 0 !important;
  min-width: 0 !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
