$color: var(--ds-neutral-300);

.mat-mdc-form-field {
  --mdc-theme-primary: #{$color};
  line-height: 1.5 !important;
  color: #{$color};
  font-weight: normal;

  mat-label,
  .mat-mdc-form-field-text-suffix {
    font-weight: normal;
  }

  [class^='mdc-notched-outline'] {
    border-width: 1px !important;
    border-color: #{$color};
  }

  &
    .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline {
    &__leading,
    &__notch,
    &__trailing {
      border-color: var(--ds-neutral-900);
      border-width: 1px !important;
    }
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    caret-color: var(--ds-neutral-900);
    color: var(--ds-neutral-900);
    font-size: calc(0.8 * var(--ds-spacing));
    font-weight: 400;
  }

  &.mat-focused .mat-mdc-select-trigger .mat-mdc-select-arrow,
  &.mat-focused:not(.mat-form-field-invalid)
    .mat-mdc-form-field-required-marker {
    color: var(--ds-neutral-900);
  }
}
.mat-mdc-form-field:not(.mat-form-field-invalid) {
  mat-label {
    color: #{$color};
  }
}
.mat-calendar-body-selected {
  color: var(--ds-neutral-contrast-700);
}
.mat-mdc-form-field-error:before {
  display: none !important;
}
mat-hint,
mat-error {
  letter-spacing: normal;
}
.mdc-menu-surface.mat-mdc-autocomplete-panel:empty {
  padding: 0;
}
